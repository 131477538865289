import { socket } from "./editor-socket";

const tpl = document.querySelector("template");
const panel = document.querySelector(".users");

function updatePanel(msg) {
  var el = document.querySelector(`div[sid='${msg.id}']`) || addToPanel(msg);
  msg.done ? el.classList.add("active") : el.classList.remove("active");
  el.querySelector(".activity").innerText = msg.activity;
}

function addToPanel({ username, done, id, activity }) {
  const el = tpl.content.cloneNode(true);
  const container = el.querySelector("div");
  container.setAttribute("sid", id);
  done && container.classList.add("active");
  el.querySelector(".name").innerText = username;
  el.querySelector(".activity").innerText = activity || 0;
  el.querySelector(".increase").addEventListener("click", () =>
    fetch(`/user/activity/${id}/+1`, { method: "post" })
  );
  el.querySelector(".decrease").addEventListener("click", () =>
    fetch(`/user/activity/${id}/-1`, { method: "post" })
  );
  el.querySelector(".remove").addEventListener("click", () =>
    fetch(`/user/remove/${id}`, { method: "delete" }).then(fetchAll)
  );
  panel.appendChild(el);
  return container;
}

function fetchAll() {
  panel.innerHTML = "";
  fetch("/user/all")
    .then((resp) => resp.json())
    .then((data) => data.filter((el) => el).map(addToPanel));
}

export function runPanel() {
  socket.on("updatePanel", (msg) => {
    if (!msg) return;
    updatePanel(msg);
  });

  document
    .querySelector(".reset")
    .addEventListener("click", () => fetch("/user/reset", { method: "post" }));

  fetchAll();
}
