import { connect, socket } from "./editor-socket";
import { runPanel } from "./panel";

// move to share file; dry
const pattern = /^.{2,15}\s.{2,15}$/;

const handRaise = document.querySelector("ui-hand-raise");

const makeDone = async ({ detail }) => {
  handRaise.setAttribute("waiting", true);
  socket.emit("done", detail);
};

function register() {
  const username = prompt(`your first and last name ${pattern}`);
  if (!username) return hidePanel();
  fetch("/user/register", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username }),
  })
    .then(() => {
      getUser();
    })
    .catch((err) => {
      alert(JSON.stringify(err, null, 4));
      register();
    });
}

export function setUser({ username, done }) {
  username && handRaise.setAttribute("username", username);
  handRaise.setAttribute("waiting", false);
  handRaise.setAttribute("checked", done);
}

function hidePanel() {
  document.querySelector(".panel").remove();
}

function showPanel(resp) {
  resp.admin ? runPanel() : hidePanel();
}

function getUser() {
  fetch("/user")
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.error) register();
      else {
        connect();
        socket.on("updateUser", (msg) => {
          if (!msg) return;
          setUser(msg);
        });
        showPanel(resp);
        setUser(resp);
      }
    });
}

//getUser();
handRaise.addEventListener("done", makeDone);
